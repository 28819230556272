<template>
  <section id="platform-settings-live">
    <!-- Live configuration -->

    <b-overlay
      :show="!platformAllowLive"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-2">
        <b-col>
          <h1>{{ $t("Live") }}</h1>
          <h5 class="text-primary">
            {{ $t("live.setting.info") }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="match-height mt-2">
        <b-col>
          <b-card>
            <!-- Live type -->
            <div id="tabsLiveType">
              <b-tabs v-model="tabIndex">
                <!-- AWS -->
                <settings-tabs-live-type-aws :is-active="platform.liveType === 'AWS' ? true : false" />
                <!-- Wowza -->
                <settings-tabs-live-type-wowza :is-active="platform.liveType === 'WOW' ? true : false" />
              </b-tabs>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-jumbotron
            bg-variant="transparent"
            border-variant="primary"
            :header="$t('desactValue')"
            class="text-center"
          >
            <b-row>
              <b-col>
                <h5>
                  {{ $t('activeShure') }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  no-body
                  @click="changeFunction(funcionalidad)"
                >
                  <b-avatar
                    class="cursor-pointer mb-2 mt-2"
                    size="70"
                    :variant="funcionalidad.value ? 'light-success' : 'light-danger'
                    "
                  >
                    <feather-icon
                      size="30"
                      :icon="funcionalidad.icon"
                    />
                  </b-avatar>
                  <h6 class="">
                    {{ funcionalidad.titulo }}
                    <feather-icon
                      v-b-tooltip.hover.top
                      :title="funcionalidad.descripcion"
                      icon="InfoIcon"
                    />
                  </h6>
                  <!-- <small>{{ funcionalidad.descripcion }}</small> -->
                </div>
              </b-col>
            </b-row>
          </b-jumbotron>
        </div>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BJumbotron,
  BAvatar,
  VBTooltip,
  BTabs,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from '@axios'
import { messageError, showToast, setClientNode } from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import SettingsTabsLiveTypeAws from './components/live/SettingsTabsLiveTypeAws.vue'
import SettingsTabsLiveTypeWowza from './components/live/SettingsTabsLiveTypeWowza.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BJumbotron,
    BAvatar,
    BTabs,
    SettingsTabsLiveTypeAws,
    SettingsTabsLiveTypeWowza,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      liveTypes: ['AWS', 'WOW'],
      tabIndex: 0,
      platform: {
        liveType: '',
      },
      platformAllowLive: false,
      funcionalidad: {},
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
    const userData = getUserData()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    this.platformAllowLive = userData.profile.client.isLive

    const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            id
            liveType
            isLive
          }
        }
      `
    axios.post('', { query }).then(res => {
      this.funcionalidad = {
        value: res.isLive,
        titulo: this.$t('resFunciones.isLive.title'),
        descripcion: this.$t('resFunciones.isLive.description'),
        icon: 'RadioIcon',
        id: 'isLive',
      }
      this.tabIndex = this.liveTypes.indexOf(res.data.data.client.liveType)
    })
  },
  created() {
    const userData = getUserData()
    this.platform = userData.profile.client
  },
  methods: {
    changeFunction() {
      const query = `
        mutation{
          updateClient(id:"${getUserData().profile.client.id}",input:{isLive:true}){
            client{
              id
              name
              isShop
              hasProfiles
              isBuy
              isInteractivity
              isFavorite
              videotype
              isSecondScreenInteractivity
              isPushInteractivity
              isBlockInteractivity
              isElearningPlatform
              hasMyChannel
              isVirtualUser
              isClose
              isPromotionalCodes
              isDownload
              isParentalControl
              isAds
              hasRating
              hasAdSlides
              hasAdCuePoints
              contentRating
              productRating
              categoryRating
              isLinkCategories
              isLive
              isTranscoding
              geoipbloking
            }
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        showToast(this.$t('success'), 1, this)
        setClientNode()
      })
    },
  },
}

</script>

<style lang="scss">
#platform-settings-live .col-form-label {
  font-size: inherit;
  line-height: 1.45;
}
</style>
