<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="s3"
      title-item-class="w-25"
    >
      <template #title>
        <b-card
          :img-src="require('@/assets/images/backend/live/wowza.png')"
          overlay
        />
      </template>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group
              class="mb-2 mr-1"
              :label="$t('live.liveWowzaAccessToken')"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="liveWowzaAccessToken"
                  :type="passwordFieldType"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col style="text-align: end;">
            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      liveWowzaAccessToken: null,
      showLoading: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getLiveWowzaPlatformData()
  },
  methods: {
    getLiveWowzaPlatformData() {
      const userData = getUserData()
      const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            wowzaAccessToken
          }
        }
      `
      axios.post('', { query }).then(res => {
        this.liveWowzaAccessToken = res.data.data.client.wowzaAccessToken
      })
    },
    onSubmit(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      const query = `
        mutation($id: ID!, $wowzaAccessToken: String,){
          updateClient(id: $id, input: {
            liveType: WOW
            wowzaAccessToken: $wowzaAccessToken
          }){
            client {
              id
              liveType
              wowzaAccessToken
            }
          }
        }
      `
      const variables = {
        id: userData.profile.client.id,
        wowzaAccessToken: this.liveWowzaAccessToken,
      }
      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(() => {
          showToast(
            this.$t('live.setting.changeSuccessMsg'),
            1, this,
          )
        })
        .catch(() => {
          showToast(this.$t('live.setting.changeFailMsg'), 2, this)
        })
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.nav-link .card {
  background-color: #f6f6f6 !important;
}

.nav-link.active .card {
  background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
}
</style>
